<template>
  <div class="component-warp">
    <div class="table-wrap">
      <div class="filters">
        <div class="input-item">
          <a-input
            allowClear
            :placeholder="$t('enterprise.table.account', 2)"
            v-model:value="queryParam.account"
          >
            <!-- 请输入账号 -->
            <template #prefix>
              <SearchOutlined style="color: #999" />
            </template>
          </a-input>
        </div>
        <div class="input-item">
          <a-input
            allowClear
            :placeholder="$t('enterprise.table.real_name', 2)"
            v-model:value="queryParam.realName"
          >
            <!-- 请输入姓名 -->
            <template #prefix>
              <SearchOutlined style="color: #999" />
            </template>
          </a-input>
        </div>
        <div class="input-item">
          <a-input
            allowClear
            :placeholder="$t('enterprise.table.package', 2)"
            v-model:value="queryParam.taskName"
          >
            <!-- 请输入培训包名称 -->
            <template #prefix>
              <SearchOutlined style="color: #999" />
            </template>
          </a-input>
        </div>
        <div class="input-item">
          <a-select
            allowClear
            :placeholder="$t('enterprise.table.study_completed', 2)"
            v-model:value="queryParam.complete"
            style="width: 100%"
          >
            <!-- 请选择完成状态 -->
            <a-select-option :value="1">{{
              $t("enterprise.learn_state", 0)
            }}</a-select-option>
            <!-- 完成 -->
            <a-select-option :value="2">{{
              $t("enterprise.learn_state", 1)
            }}</a-select-option>
            <!-- 进行中 -->
            <a-select-option :value="3">{{
              $t("enterprise.learn_state", 2)
            }}</a-select-option>
            <!-- 逾期未完成 -->
          </a-select>
        </div>
        <div class="input-item">
          <a-range-picker
            valueFormat="YYYY-MM-DD"
            v-model:value="queryParam.learnDate"
          />
        </div>
        <a-button type="primary" @click="handleSearch(queryParam)">{{
          $t("XB_Search")
        }}</a-button>
        <!-- 搜索 -->
        <a-button style="margin-left: 10px" @click="handleSearch()">
          {{ $t("CM_Btnreset") }}
          <!-- 重置 -->
        </a-button>
      </div>
      <a-table
        :scroll="{ x: 1200 }"
        :columns="columns"
        :data-source="state.dataSource"
        :rowKey="(record, index) => index"
        :bordered="false"
        :pagination="pagination"
        :loading="state.loading"
        @change="handleTableChange"
      >
        <template #realName="{ text }">
          <OpenData type="userName" :openid="text" />
        </template>
        <template #qualifier="{ text }">
          <span v-if="text == 1">{{ $t("enterprise.pass_state", 1) }}</span>
          <!-- 已通过 -->
          <span v-else-if="text == 2">{{
            $t("enterprise.pass_state", 2)
          }}</span>
          <!-- 未通过 -->
        </template>
        <template #complete="{ text }">
          <span v-if="text == 1">{{ $t("enterprise.learn_state", 0) }}</span>
          <!-- 完成 -->
          <span v-else-if="text == 2">{{
            $t("enterprise.learn_state", 1)
          }}</span>
          <!-- 进行中 -->
          <span v-else-if="text == 3">{{
            $t("enterprise.learn_state", 2)
          }}</span>
          <!-- 逾期未完成 -->
        </template>
        <template #status="{ text }">
          <span v-if="text == 1">{{ $t("enterprise.info_state", 1) }}</span>
          <!-- 正常 -->
          <span v-else-if="text == 8">{{
            $t("enterprise.info_state", 2)
          }}</span>
          <!-- 停用 -->
        </template>
        <template #progress="{ text }"> {{ text }}% </template>
        <template #times="{ record }">
          {{ dateFormat(record.startTime) }} ~ {{ dateFormat(record.endTime) }}
        </template>
        <template #action="{ record }">
          <div class="action" @click="toDetail(record)">
            <a-button type="link"> {{ $t("Pub_Tab_Detail") }} </a-button>
            <!-- 详情 -->
          </div>
        </template>
      </a-table>

      <a-drawer
        width="640"
        placement="right"
        :closable="false"
        :visible="infoVisible"
        @close="infoVisible = false"
      >
        <div v-for="stage in infoData" :key="stage.stageId" class="stage-info">
          <h2 class="h2">
            {{ stage.stageName }}
          </h2>
          <div v-for="detail in stage.details" :key="detail.detailId">
            <h3 class="h3">{{ detail.detailName }}</h3>
            <a-row>
              <a-col :span="12">
                <p>
                  资源类型：
                  <strong>{{ getDetailType(detail.resourceType) }}</strong>
                </p>
              </a-col>
              <a-col :span="12">
                <p>
                  完成状态：
                  <strong v-if="detail.complete == 0">未开始</strong>
                  <strong v-else-if="detail.complete == 1">完成</strong>
                  <strong v-else-if="detail.complete == 2">进行中</strong>
                  <strong v-else-if="detail.complete == 3">逾期未完成</strong>
                </p>
              </a-col>
              <a-col :span="12" v-if="detail.resourceType == 3">
                <p>
                  考试得分：
                  <strong v-if="detail.complete == 0">- -</strong>
                  <strong v-else>{{ detail.examScore }}</strong>
                </p>
              </a-col>
              <a-col :span="12" v-if="detail.resourceType == 3">
                <p>
                  已通过状态：
                  <strong v-if="detail.qualifier > 0">{{
                    detail.qualifier == 2
                      ? "待批阅"
                      : detail.qualifier == 1
                      ? "已通过"
                      : "不通过"
                  }}</strong>
                  <strong v-else>- -</strong>
                </p>
              </a-col>
              <a-col :span="12" v-else>
                <p>
                  学习进度：
                  <strong>{{ detail.progress }}%</strong>
                </p>
              </a-col>
              <a-col :span="detail.resourceType == 3 ? 24 : 12">
                <p>
                  {{ detail.resourceType == 3 ? "考试用时" : "学习时长" }}：
                  <strong v-if="detail.complete == 0">- -</strong>
                  <strong v-else>{{ formatSeconds(detail.learnTime) }}</strong>
                </p>
              </a-col>
              <a-col :span="12">
                <p>
                  开始时间：
                  <strong v-if="detail.startTime > 0">{{
                    dateFormat(detail.startTime)
                  }}</strong>
                  <strong v-else>- -</strong>
                </p>
              </a-col>
              <a-col :span="12" v-if="detail.complete == 1">
                <p>
                  完成时间：
                  <strong>{{ dateFormat(detail.completeTime) }}</strong>
                </p>
              </a-col>
              <a-col :span="12" v-else>
                <p>
                  {{ detail.resourceType == 3 ? "提交时间" : "最后学习时间" }}：
                  <strong v-if="detail.lastTime > 0">{{
                    dateFormat(detail.lastTime)
                  }}</strong>
                  <strong v-else>- -</strong>
                </p>
              </a-col>
            </a-row>
          </div>
          <a-divider />
        </div>
      </a-drawer>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, toRaw } from "vue";
import { useRouter } from "vue-router";
import { enterpriseMemberLearn } from "@/api/user";
import { useFetchData } from "@/utils/useFetchData";
import { dateFormat, formatSeconds } from "@/utils/tools";
import { getDetailType } from "@/utils/business";
import OpenData from "@/components/OpenData.vue";
export default {
  components: {
    OpenData,
  },
  setup() {
    const { t: $t } = useI18n();
    const router = useRouter();
    const stateData = reactive({
      columns: [
        {
          title: $t("enterprise.table.real_name", 1),
          // title: "姓名",
          dataIndex: "realName",
          width: 160,
          ellipsis: true,
          slots: { customRender: "realName" },
        },
        {
          title: $t("enterprise.table.account", 1),
          // title: "账号",
          dataIndex: "account",
          width: 160,
          ellipsis: true,
        },
        {
          title: $t("enterprise.table.package", 1),
          // title: "培训包",
          dataIndex: "taskName",
          width: 200,
          ellipsis: true,
        },
        {
          title: $t("enterprise.table.study_time"),
          // title: "学习时间"
          dataIndex: "times",
          width: 320,
          slots: { customRender: "times" },
        },
        {
          title: $t("enterprise.table.whether_to_pass"),
          // title: "是否已通过",
          dataIndex: "qualifier",
          width: 150,
          slots: { customRender: "qualifier" },
        },
        {
          title: $t("enterprise.table.learning_progress"),
          // 学习进度
          dataIndex: "progress",
          width: 170,
          slots: { customRender: "progress" },
        },
        {
          title: $t("enterprise.table.study_completed", 1),
          // 学习完成
          dataIndex: "complete",
          width: 150,
          slots: { customRender: "complete" },
        },
        {
          title: $t("cm_operate"),
          // 操作
          dataIndex: "action",
          fixed: "right",
          width: 100,
          slots: { customRender: "action" },
        },
      ],
      infoVisible: false,
      infoData: [],
    });

    const getList = (params) => {
      let form = JSON.parse(JSON.stringify(queryParam));
      form.page = params.current;
      form.pageSize = params.pageSize;

      return enterpriseMemberLearn(form).then((res) => {
        return {
          success: true,
          data: res.data.list,
          current: res.data.page,
          pageSize: res.data.pageSize,
          total: res.data.totals,
        };
      });
    };

    const {
      stripe,
      reload,
      setPageInfo,
      context: state,
    } = useFetchData(getList, {
      current: 1,
      pageSize: 10,
      tableSize: "middle", // 'default' | 'middle' | 'small'
      stripe: false,
    });

    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
      if (sorter.field != undefined) {
        if (
          sorter.field != queryParam.sortKey ||
          sorter.order != queryParam.sort
        ) {
          current = 1;
        }
      }
      queryParam.sortKey = sorter.field;
      queryParam.sort = sorter.order;

      setPageInfo({
        current,
        pageSize,
        sortKey: sorter.field,
        sort: sorter.order,
        ...filters,
      });

      reload();
    };
    const { total, pageSize, current } = toRefs(state);

    const pagination = reactive({
      showSizeChanger: true,
      showQuickJumper: false,
      pageSizeOptions: ["10", "30", "50", "100"],
      current,
      pageSize,
      total,
      onChange: (page, pageSize) => {
        setPageInfo({ current: page, pageSize });
        reload();
      },
      onShowSizeChange: (current, size) => {
        setPageInfo({ current, pageSize: size });
        reload();
      },
    });

    let queryParam = reactive({
      account: "",
      realName: "",
      taskName: "",
    });

    const handleSearch = (searchModel) => {
      if (searchModel) {
        queryParam = searchModel;
      } else {
        queryParam.account = "";
        queryParam.realName = "";
        queryParam.taskName = "";
        delete queryParam.complete;
        delete queryParam.learnDate;
      }
      setPageInfo({ current: 1, ...toRaw(queryParam) });
      reload();
    };

    const toDetail = (data) => {
      router.push({
        path: "/enterprise/study",
        query: {
          taskId: data.taskId,
          userId: data.userId,
        },
      });
    };

    return {
      dateFormat,
      formatSeconds,
      getDetailType,
      ...toRefs(stateData),
      state,
      pagination,
      handleTableChange,
      queryParam,
      handleSearch,
      toDetail,
    };
  },
};
</script>

<style lang="less" scoped>
.component-warp {
  min-height: 800px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  padding: 16px 32px 32px 32px;
}
.table-wrap {
  .filters {
    .mixinFlex(flex-start);
    flex-wrap: wrap;
    padding-bottom: 10px;
    .input-item {
      width: 240px;
      margin: 0 20px 6px 0;
    }
  }
  ::v-deep(.ant-table-pagination.ant-pagination) {
    float: none;
    text-align: center;
  }
  .action {
    ::v-deep(.ant-btn) {
      padding-left: 0;
    }
  }
}
</style>
<style scoped>
.stage-info >>> .h2 {
  font-size: 15px;
  color: #000;
}
.stage-info >>> .h3 {
  font-size: 14px;
  color: #333;
  padding: 5px 0 5px 10px;
  position: relative;
}
.stage-info >>> .h3::before {
  content: "";
  width: 2px;
  height: 14px;
  background-color: #5f85ed;
  position: absolute;
  left: 0;
  top: 9px;
}
</style>
